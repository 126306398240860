import classNames from "classnames";
import Link from "next/link";

import useDomainCountryCode from "hooks/useDomainCountryCode";
import { dispensaryHref } from "utils/dispensaryUrls";
import { trackMenuEvent } from "utils/trackEvent";

const ShopAllButton: React.FC<{
  className?: string;
  dispensarySlug: string;
  dispensaryRetailType: string;
}> = ({ className, dispensarySlug, dispensaryRetailType }) => {
  const countryCode = useDomainCountryCode();

  const href = dispensaryHref({
    additionalPath: "/menu",
    countryCode,
    retailType: dispensaryRetailType,
    slug: dispensarySlug,
  });

  const trackButtonClick = () =>
    trackMenuEvent("Click", "Shop All Products Button");

  return (
    <div
      data-testid="shop-all-button"
      className={classNames("row px-md", className)}
    >
      <Link
        data-testid="shop-all-link"
        href={href}
        role="button"
        onClick={trackButtonClick}
        onKeyPress={trackButtonClick}
        className="button button--secondary"
      >
        shop all products
      </Link>
    </div>
  );
};

export default ShopAllButton;
