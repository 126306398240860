import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { jsonLdScriptProps } from "react-schemaorg";
import { LocalBusiness } from "schema-dts";

import getRegionComplianceRules from "api/requests/consumerApi/getRegionComplianceRules";
import getBrandPlacement, { Placement } from "api/requests/getBrandPlacement";
import { getDispensary } from "api/requests/getDispensary";
import { getDispensaryDeals } from "api/requests/getDispensaryDeals";
import getDispensaryReviews from "api/requests/getDispensaryReviews";
import getMenuItems from "api/requests/getMenuItems";
import { DISPENSARY_PAGES } from "constants/dispensary";
import { Deal } from "custom-types/Deals";
import { Dispensary, RetailType } from "custom-types/Dispensary";
import { MenuItem } from "custom-types/MenuItem";
import { DispensaryReview } from "custom-types/Reviews";
import useDomain from "hooks/useDomain";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import {
  dispensaryJsonSchema,
  retailerMainMetaData,
} from "lib/dispensaryMetadata";
import {
  COMPLIANCE_RULESET_LOAD,
  ComplianceRuleset,
} from "redux/reducers/complianceRules";
import {
  getIsOrderingAllowed,
  getIsThirdPartyOrderingProhibited,
} from "redux/selectors/complianceRules";
import { createGetServerSideProps } from "utils/createGetServerSideProps";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import { getDispensaryUrl } from "utils/dispensaryUrls";
import { selectCategories } from "utils/filterUtils";
import generateDispensaryRedirect from "utils/generateDispensaryRedirect";
import logError from "utils/logError";
import { getMenuTypeCookie } from "utils/menuTypeUtils";
import { getCountryCodeHeader } from "utils/requestContext";
import useTrackDispensaryPageViewSplitEvent from "utils/split/useTrackDispensaryPageViewSplitEvent";
import { trackUserHistory } from "utils/userHistory";

import DispensaryBreadcrumbs from "components/Dispensary/DispensaryBreadcrumbs";
import DispensaryHeader from "components/Dispensary/DispensaryHeader";
import DispensaryTabs from "components/Dispensary/DispensaryTabs";
import MenuNotAvailable from "components/Dispensary/MenuNotAvailable";
import NonCompliantMenu from "components/Dispensary/NonCompliantMenu";
import PaidTierMenu from "components/Dispensary/PaidTierMenu";
import { ProductRecTrayData } from "components/Dispensary/ProductRecTrays/ProductRecTrays";
import UberEgress from "components/UberEgress";

import MetaTags from "../../components/MetaTags";

const NearbyStores = dynamic(
  () => import("components/Dispensary/NearbyStores"),
);
const AboutDispensary = dynamic(
  () => import("components/Dispensary/AboutDispensary"),
);
const ReviewConfirmationModal = dynamic(
  () => import("components/Dispensary/ReviewsHeader/ReviewConfirmationModal"),
);

type MainPageProps = {
  complianceRuleset: ComplianceRuleset;
  brandPlacement: Placement | null;
  deals: Deal[];
  dispensary: Dispensary;
  productsForCategoryCarousels: ProductRecTrayData[];
  staffPicks: MenuItem[];
  reviews: DispensaryReview[];
};

const MainPage: React.FC<MainPageProps> = ({
  complianceRuleset,
  brandPlacement,
  deals,
  dispensary,
  productsForCategoryCarousels,
  reviews,
}) => {
  const {
    id,
    city,
    coverPhotoUrl,
    featureTier,
    hasMenuItems,
    logoUrl,
    name,
    retailType,
    slug,
    state,
    uberEatsStoreUrl,
  } = dispensary;
  const baseUrl = useDomain();
  const dispatch = useDispatch();

  const isInfoTier = featureTier === "info";
  const isDispensaryRetailType = retailType === RetailType.Dispensary;

  useTrackDispensaryPageViewSplitEvent({ page: "main", tier: featureTier });

  useEffect(() => {
    if (complianceRuleset) {
      dispatch({
        complianceRuleset,
        type: COMPLIANCE_RULESET_LOAD,
      });
    }
  }, []);

  useEffect(() => {
    trackUserHistory({
      resourceId: id,
      slug,
      type: "dispensary",
    });
  }, [id]);

  const router = useRouter();
  const countryCode = useDomainCountryCode();
  const thirdPartyOrderingProhibited = useSelector(
    getIsThirdPartyOrderingProhibited,
  );

  let isLeaflyOrderingAllowed = useSelector(getIsOrderingAllowed);
  // * Only "dispensary" types are restricted, since clinic and cbd-store types
  // * typically don't sell restricted items.
  if (!isDispensaryRetailType) {
    isLeaflyOrderingAllowed = true;
  }

  const showConfirmationModal = !!router.query?.show_confirmation_modal;
  const showFavoriteDispensaryMessage = !!router.query?.show_favorite_message;

  const ssoMenuRedirect = async () => {
    try {
      await router.push(
        getDispensaryUrl({
          countryCode,
          slug,
          tab: "menu",
        }),
      );
    } catch (err) {
      logError(
        "Error redirecting in ssoMenuRedirect on /dispensary-info/[slug]",
      );
    }
  };

  const ssoDispensaryRedirect = async () => {
    try {
      await router.push(
        getDispensaryUrl({
          countryCode,
          slug,
        }),
      );
    } catch (err) {
      logError(
        "Error redirecting in ssoDispensaryRedirect on /dispensary-info/[slug]",
      );
    }
  };

  const { description, image, title } = retailerMainMetaData(
    city,
    countryCode,
    coverPhotoUrl,
    logoUrl,
    name,
    retailType as RetailType,
    state,
  );

  return (
    <>
      <MetaTags
        description={description}
        image={image}
        shouldShowAlternates={false}
        title={title}
      >
        <script
          {...jsonLdScriptProps<LocalBusiness>(
            dispensaryJsonSchema(
              dispensary,
              baseUrl,
              dispensary.country || countryCode,
              reviews,
            ),
          )}
        />
      </MetaTags>
      <DispensaryHeader dispensary={dispensary} isMainPage={true} />
      {thirdPartyOrderingProhibited && isDispensaryRetailType && (
        <NonCompliantMenu
          dispensaryId={dispensary.id}
          dispensaryName={dispensary.name}
          dispensaryWebsite={dispensary.website}
          hideCTA={isInfoTier}
          thirdPartyOrderingProhibited={thirdPartyOrderingProhibited}
          isLeaflyOrderingAllowed={isLeaflyOrderingAllowed}
        />
      )}
      <DispensaryTabs
        activeTab={DISPENSARY_PAGES.main}
        dispensary={dispensary}
      />
      {uberEatsStoreUrl && (
        <UberEgress
          url={uberEatsStoreUrl}
          className="container mt-lg md:hidden"
          dispensaryId={dispensary.id}
        />
      )}
      <div className="flex flex-col gap-xxl">
        {!isInfoTier && hasMenuItems ? (
          <PaidTierMenu
            dispensary={dispensary}
            brandPlacement={brandPlacement}
            deals={deals}
            productsForCategoryCarousels={productsForCategoryCarousels}
          />
        ) : (
          <>
            <MenuNotAvailable
              dispensaryFeatureTier={dispensary.featureTier}
              dispensaryId={dispensary.id}
              dispensaryName={dispensary.name}
              dispensaryRetailType={dispensary.retailType}
            />
            {isInfoTier && (
              <NearbyStores
                dispensaryId={dispensary.id}
                locations={dispensary.locations}
                locationPaths={dispensary.locationPaths}
                className="container mt-xl md:mt-[40px] mb-lg"
              />
            )}
          </>
        )}
        <AboutDispensary dispensary={dispensary} reviews={reviews} />
        {showConfirmationModal && (
          <ReviewConfirmationModal
            dispensaryId={dispensary.id}
            favorite={showFavoriteDispensaryMessage}
            ssoMenuRedirect={ssoMenuRedirect}
            ssoDispensaryRedirect={ssoDispensaryRedirect}
          />
        )}
        <DispensaryBreadcrumbs dispensary={dispensary} />
      </div>
    </>
  );
};

export const getServerSideProps = createGetServerSideProps<MainPageProps>(
  async (context) => {
    const { query, req, resolvedUrl } = context;
    const { slug, menu_type } = query;

    const dispensary = await getDispensary(slug as string);

    const domainCountryCode = getCountryCodeHeader(req.headers);

    const redirect = generateDispensaryRedirect(
      domainCountryCode,
      resolvedUrl,
      query,
      dispensary,
    );

    if (redirect) {
      return { redirect };
    }

    // fallback if redirect not generated
    if (!dispensary) {
      return {
        notFound: true,
      };
    }

    const userMedRecPreference = menu_type
      ? Array.isArray(menu_type)
        ? String(menu_type)
        : menu_type
      : getMenuTypeCookie(req);
    const isDualLicenseDispensary = isDispensaryDualLicense(
      dispensary.tags || [],
    );

    const menuItemsCall = getMenuItems(slug as string, {
      enableNewFilters: true,
      menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,
    });

    const complianceCall = getRegionComplianceRules(
      dispensary.state,
      dispensary.country,
    );

    const brandPlacementCall = getBrandPlacement(dispensary.slug, {
      menuType: isDualLicenseDispensary ? userMedRecPreference : undefined,
      strategies: ["lite-amplification", "menu-merchandising"],
    });

    const dealsCall = getDispensaryDeals(dispensary.slug);

    const staffPicksCall = getMenuItems(slug as string, {
      is_staff_pick: true,
      menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,
    });

    const reviewsCall = getDispensaryReviews(dispensary.slug, 0, 4);

    const [
      menuData,
      complianceRuleset,
      brandPlacement,
      { data: deals },
      { menuItems: staffPicks },
      { reviews },
    ] = await Promise.all([
      menuItemsCall,
      complianceCall,
      brandPlacementCall,
      dealsCall,
      staffPicksCall,
      reviewsCall,
    ]);

    const categories = selectCategories(menuData.availableFilters);

    // Temporary: We will create a new endpoint that will simplify the multiple calls to
    // getMenuItems down to one call.
    const productsForCategoryCarousels = await Promise.all(
      // Make menu item requests for each category
      categories.map((category) =>
        getMenuItems(dispensary.slug, {
          is_staff_pick: false,

          menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,

          perPage: 8,

          product_category: [category],
          /**
           * Canadian brands are not currently offered the lite-amplification
           * product that drives this promotional placement, but there is not
           * currently a way to differentiate whether to show this placement
           * depending on whether a brand has been sold this functionality or
           * not. When the integration of brand accounts in salesforce is
           * complete, this can be removed.
           *
           * Tracking ticket: https://leafly.atlassian.net/browse/BRND-419
           */
          promotedSlots: domainCountryCode === "CA" ? 0 : 1,
        }),
      ),
    )
      .then((menuItemsByCategory) =>
        menuItemsByCategory.map(({ menuItems }, i) => {
          return {
            menuItems,
            name: categories[i],
          };
        }),
      )
      .catch(() => []);

    return {
      props: {
        brandPlacement,
        complianceRuleset,
        deals,
        dispensary,
        productsForCategoryCarousels,
        reviews,
        staffPicks,
      },
    };
  },
);

export default MainPage;
