import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";
import { trackEvent } from "@leafly-com/web-utils";

import { CATEGORY_NAMES } from "constants/dispensary";
import { MenuItem } from "custom-types/MenuItem";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { dispensaryHref } from "utils/dispensaryUrls";
import { getSplitKey } from "utils/split/getSplitKey";

import Carousel from "components/botanic/Carousel";
import MenuCard from "components/Dispensary/MenuCard";
import TrackImpression from "components/TrackImpression";

export type ProductRecTrayData = {
  menuItems: MenuItem[];
  name: string;
};

type Props = {
  productsForCategoryCarousels: ProductRecTrayData[];
  dispensarySlug: string;
  dispensaryRetailType: string;
};

const ProductRecTrays: React.FC<Props> = ({
  dispensarySlug,
  dispensaryRetailType,
  productsForCategoryCarousels = [],
}) => {
  const countryCode = useDomainCountryCode();

  const trackRecTrayEvent = (label: string) => {
    trackEvent("Dispensary Menu Rec Tray", "Click", label);
  };

  if (!productsForCategoryCarousels.length) {
    return null;
  }

  const splitKey = getSplitKey();

  return (
    <>
      {productsForCategoryCarousels.map(({ name, menuItems = [] }) => {
        if (!menuItems.length) {
          return null;
        }

        const displayName = CATEGORY_NAMES[name] || name;

        const menuLink = dispensaryHref({
          additionalPath: `/menu?product_category[]=${name}`,
          countryCode,
          retailType: dispensaryRetailType,
          slug: dispensarySlug,
        });

        const trackCardClick = () => trackRecTrayEvent(displayName);
        const trackSeeAllClick = () =>
          trackRecTrayEvent(`${displayName} - show all`);

        return (
          <div key={name} data-testid="product-rec-tray">
            <Carousel
              seeMoreHref={menuLink}
              seeMoreOnClick={trackSeeAllClick}
              title={
                <h2
                  className="heading--m font-extrabold"
                  data-testid="section-header"
                >
                  {displayName}
                </h2>
              }
              data-testid={`${name}-carousel`}
            >
              {menuItems.map((item, i) => {
                if (item.promoted) {
                  const trackingCategory = "Dispensary Menu Rec Tray";
                  const trackingLabel = `${displayName} Lite-Amp Card`;
                  const trackingDimensions = {
                    brandId: item.brand?.id,
                    carouselSlot: i + 1,
                    dispensaryId: item.dispensaryId,
                    menuItemId: item.id,
                    productId: item.product?.id,
                  };

                  return (
                    <TrackImpression
                      category={trackingCategory}
                      action="impression"
                      className="h-full pb-sm"
                      customDimensions={trackingDimensions}
                      key={item.id}
                      label={trackingLabel}
                    >
                      <MenuCard
                        dispensaryId={item.dispensary.id}
                        menuItem={item}
                        onClick={() => {
                          trackCardClick();
                          trackEvent(
                            trackingCategory,
                            "Click",
                            trackingLabel,
                            trackingDimensions,
                          );
                        }}
                        onAddToBagClick={() => {
                          trackEvent(
                            "Dispensary Menu Rec Tray",
                            "Click",
                            `${trackingLabel} ATB`,
                            trackingDimensions,
                          );
                          sendClientSideSplitTrackEvent(
                            "webDispensaryMenuItem_addToBagClick_frontend",
                            splitKey,
                          );
                        }}
                      />
                    </TrackImpression>
                  );
                } else {
                  return (
                    <MenuCard
                      dispensaryId={item.dispensary.id}
                      menuItem={item}
                      onClick={trackCardClick}
                      key={item.id}
                      onAddToBagClick={() => {
                        sendClientSideSplitTrackEvent(
                          "webDispensaryMenuItem_addToBagClick_frontend",
                          splitKey,
                        );
                      }}
                    />
                  );
                }
              })}
            </Carousel>
          </div>
        );
      })}
    </>
  );
};

export default ProductRecTrays;
