import { useContext } from "react";
import Link from "next/link";
import { useSelector } from "react-redux";

import { Action, Category } from "constants/events";
import MenuContext from "context/MenuContext";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getAreCategoryCoinIconsHidden } from "redux/selectors/complianceRules";
import { dispensaryHref } from "utils/dispensaryUrls";

import IconSvg from "components/IconSvg";
import TrackImpression from "components/TrackImpression";

const StrainCarousel: React.FC<{
  className?: string;
  dispensaryId: number;
  dispensarySlug: string;
  dispensaryRetailType: string;
}> = ({ className, dispensaryId, dispensarySlug, dispensaryRetailType }) => {
  const { publishEvent } = useEventTracker();
  const countryCode = useDomainCountryCode();
  const hideCategoryIcons = useSelector(getAreCategoryCoinIconsHidden);

  const {
    selectors: { availableFilters },
  } = useContext(MenuContext);

  const strainTypes = availableFilters
    ?.find((option) => option.name === "strain_category")
    ?.values?.map((value) => value.value);

  if (!strainTypes || !strainTypes.length) {
    return null;
  }

  const strainTypeList = ["Indica", "Sativa", "Hybrid"]
    .filter((type) => strainTypes.includes(type))
    .map((type) => ({
      link: dispensaryHref({
        additionalPath: `/menu?strain_category=${type}`,
        countryCode,
        retailType: dispensaryRetailType,
        slug: dispensarySlug,
      }),
      name: type,
    }));

  return (
    <div data-testid="strain-carousel" className={className}>
      <h2
        className="mb-lg heading--m font-extrabold text-center md:text-left"
        data-testid="strain-carousel-header"
      >
        Shop by strain type
      </h2>
      <ul className="flex gap-lg justify-center md:justify-start">
        {strainTypeList.map(({ link, name }) => (
          <TrackImpression
            key={name}
            category={Category.dispensaryMenuStrainTypeShortcut}
            label={name}
            customDimensions={{ dispensaryId }}
          >
            <li className="flex-shrink-0" style={{ minWidth: 80, width: 80 }}>
              <Link
                href={link}
                data-testid={`strain-type-${name.toLowerCase()}`}
                onClick={() => {
                  publishEvent({
                    action: Action.click,
                    category: Category.dispensaryMenuStrainTypeShortcut,
                    dispensaryId,
                    label: name,
                  });
                }}
                className="flex border border-grey-44 flex-col items-center justify-center h-[80px] bg-green-20 rounded-[8px]"
              >
                {!hideCategoryIcons ? (
                  <IconSvg
                    className={`flex items-center text-white`}
                    width="52"
                    height="52"
                    filePath={`phenotypes/${name.toLowerCase()}_white.svg`}
                  />
                ) : null}
                <span className="text-xs">{name.toLowerCase()}</span>
              </Link>
            </li>
          </TrackImpression>
        ))}
      </ul>
    </div>
  );
};

export default StrainCarousel;
