import React, { useEffect, useState } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";
import classnames from "classnames";
import { stringify } from "qs";
import { useSelector } from "react-redux";

import consumerApi from "api/services/consumer-api";
import { Action, Category, Label } from "constants/events";
import { MenuItem } from "custom-types/MenuItem";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getCoordinates } from "redux/selectors/location";
import { getUserId, getUserPrefersMedical } from "redux/selectors/user";
import { getRoundedDistance } from "utils/distance";
import logError from "utils/logError";
import { getSplitKey } from "utils/split/getSplitKey";
import { getUserHistoryItems, UserHistoryEntryInput } from "utils/userHistory";

import Carousel from "components/botanic/Carousel";
import MenuItemCard from "components/botanic/MenuItemCard";
import { getMenuItemIndicator } from "components/Dispensary/MenuCard/MenuCard";
import { MenuCardSkeleton } from "components/Shared/Skeletons";
import TrackImpression from "components/TrackImpression";

import { MenuItemCardActions } from "../MenuItemCarousel/MenuItemCardActions";

type Props = {
  attributes?: UserHistoryEntryInput["attributes"];
  carouselClassName?: string;
  className?: string;
  displayStaffPick?: boolean;
  excludedId?: number;
  isDispensaryDualLicense?: boolean;
  isDispensaryPage?: boolean;
  menuItemProps?: Partial<React.ComponentProps<typeof MenuItemCard>>;
  onCardClick?: (item: MenuItem) => void;
  onCardImpression?: (item: MenuItem) => void;
};

type MenuItemsResponse = {
  data: MenuItem[];
};

const ITEMS_PER_CAROUSEL = 8;

const RecentlyViewedMenuItems: React.FC<Props> = ({
  attributes,
  carouselClassName,
  className,
  displayStaffPick,
  excludedId,
  isDispensaryPage,
  isDispensaryDualLicense,
  menuItemProps,
  onCardClick,
  onCardImpression,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [recentMenuItems, setRecentMenuItems] = useState<MenuItem[]>([]);
  const [filteredMenuItems, setFilteredMenuItems] = useState<MenuItem[]>([]);

  const { publishEvent } = useEventTracker();
  const countryCode = useDomainCountryCode();

  const coordinates = useSelector(getCoordinates);
  const userId = useSelector(getUserId);
  const userPrefersMedical = useSelector(getUserPrefersMedical);

  useEffect(() => {
    const getMenuItems = async (menuItemIds: number[]) => {
      try {
        const queryString = stringify(
          {
            filter: {
              menu_item_id: menuItemIds,
            },
            ...(!isDispensaryPage && coordinates),
          },
          { arrayFormat: "brackets" },
        );

        let {
          data: { data: menuItems },
        } = await consumerApi.get<MenuItemsResponse>(
          `/api/menu_items/v2?${queryString}`,
        );

        menuItems = menuItems.sort((a, b) => {
          return (
            menuItemIds.indexOf(a.menuItemId) -
            menuItemIds.indexOf(b.menuItemId)
          );
        });

        setRecentMenuItems(menuItems);
        setIsLoading(false);
      } catch (e) {
        if (e.message && e.statusCode) {
          logError(e.message, {
            functionName: "RecentlyViewedMenuItems getMenuItems",
            service: "consumer",
            statusCode: e.statusCode,
          });
        } else {
          logError(
            "error processing results in RecentlyViewedMenuItems useEffect",
          );
        }

        setIsLoading(false);
      }
    };

    const fetchRecentlyViewed = async () => {
      const getRecentlyViewedMenuItemIds = async () => {
        const entries = await getUserHistoryItems(
          "menu-item",
          attributes,
          ITEMS_PER_CAROUSEL + 1,
        );

        return entries
          .map((entry) => entry.resourceId)
          .filter((id) => id !== excludedId)
          .slice(0, 8);
      };

      const menuItemIds = await getRecentlyViewedMenuItemIds();

      if (!menuItemIds?.length) {
        setIsLoading(false);
      } else {
        getMenuItems(menuItemIds);
      }
    };

    fetchRecentlyViewed();
  }, []);

  useEffect(() => {
    const menuItems = isDispensaryDualLicense
      ? recentMenuItems.filter(
          (menuItem) => menuItem.medical && userPrefersMedical,
        )
      : recentMenuItems;

    setFilteredMenuItems(menuItems);
  }, [
    isDispensaryDualLicense,
    userPrefersMedical,
    JSON.stringify(recentMenuItems),
  ]);

  if (!isLoading && !filteredMenuItems.length) {
    return null;
  }

  const trackRecentlyViewedClick = (
    label: string,
    { menuItemId, id, brand, strain, dispensary }: MenuItem,
  ) => {
    publishEvent({
      action: Action.click,
      brandId: brand?.id as number,
      category: Category.recentlyViewedMenuItems,
      dispensaryDistance: getRoundedDistance(
        dispensary.distanceMi,
        countryCode,
      ),
      dispensaryId: dispensary.id,
      label,
      menuItemId,
      productId: id,
      strainId: strain?.id as number,
      userId: userId as number,
    });
  };

  return (
    <div className={classnames(className)}>
      <Carousel
        className={classnames(carouselClassName)}
        title={
          <h2 className="heading--m font-extrabold">{"You recently viewed"}</h2>
        }
      >
        {!isLoading
          ? filteredMenuItems.map((menuItem) => (
              <TrackImpression
                key={menuItem.id}
                className="h-full"
                trackingFunction={() => onCardImpression?.(menuItem)}
              >
                <MenuItemCard
                  handleCardClick={() => {
                    trackRecentlyViewedClick(
                      Label.menuItemCardViewDetails,
                      menuItem,
                    );
                    onCardClick?.(menuItem);
                  }}
                  menuCardIndicators={getMenuItemIndicator(
                    menuItem,
                    displayStaffPick,
                  )}
                  menuItem={menuItem}
                  showFulfillment={!isDispensaryPage}
                  {...menuItemProps}
                >
                  <MenuItemCardActions
                    menuItem={menuItem}
                    viewDetailsOnClick={() => {
                      trackRecentlyViewedClick(
                        Label.menuItemCardViewDetailsButton,
                        menuItem,
                      );
                    }}
                    onReservation={() => {
                      sendClientSideSplitTrackEvent(
                        "webDispensaryMenuItem_addToBagClick_frontend",
                        getSplitKey(),
                      );
                    }}
                  />
                </MenuItemCard>
              </TrackImpression>
            ))
          : new Array(4)
              .fill(" ")
              .map((_, ind) => (
                <MenuCardSkeleton key={ind} className="rounded shadow-low" />
              ))}
      </Carousel>
    </div>
  );
};

export default RecentlyViewedMenuItems;
