import { Placement } from "api/requests/getBrandPlacement";
import { Deal } from "custom-types/Deals";
import { Dispensary } from "custom-types/Dispensary";
import { MedicalIdCondition } from "custom-types/MedicalIdCondition";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";

import ActiveDealsCarousel from "components/Dispensary/ActiveDealsCarousel";
import CategoryCarousel from "components/Dispensary/CategoryCarousel";
import LastUpdatedTime from "components/Dispensary/LastUpdatedTime";
import MedicalInfo from "components/Dispensary/MedicalInfo";
import PhenotypeCarousel from "components/Dispensary/PhenotypeCarousel";
import ProductRecTrays from "components/Dispensary/ProductRecTrays";
import PromotedBrandCarousel from "components/Dispensary/PromotedBrandCarousel";
import SearchBar from "components/Dispensary/SearchBar";
import ShopAllButton from "components/Dispensary/ShopAllButton";
import StaffPicksCarousel from "components/Dispensary/StaffPicksCarousel";
import RecentlyViewedMenuItems from "components/Shared/RecentlyViewedMenuItems";

import { ProductRecTrayData } from "../ProductRecTrays/ProductRecTrays";

const PaidTierMenu: React.FC<{
  brandPlacement: Placement | null;
  deals: Deal[];
  dispensary: Dispensary;
  productsForCategoryCarousels: ProductRecTrayData[];
}> = ({ brandPlacement, deals, dispensary, productsForCategoryCarousels }) => {
  const isMedIdRequired =
    dispensary.orderMedIdCondition === MedicalIdCondition.Required;

  return (
    <div className="bg-white pt-xl">
      <div className="container">
        {isMedIdRequired && (
          <MedicalInfo
            dispensaryId={dispensary.id}
            dispensaryRetailType={dispensary.retailType}
            dispensaryTags={dispensary.tags}
          />
        )}

        <SearchBar
          dispensaryId={dispensary.id}
          dispensaryRetailType={dispensary.retailType}
          dispensarySlug={dispensary.slug}
          dispensaryTags={dispensary.tags}
          className="mb-sm mt-md"
          routeToMenu
        />

        <LastUpdatedTime
          lastMenuUpdate={dispensary.lastMenuUpdate}
          className="mb-xl"
        />

        <div className="flex flex-col gap-xl">
          <CategoryCarousel
            dispensaryRetailType={dispensary.retailType}
            dispensarySlug={dispensary.slug}
          />

          <PromotedBrandCarousel
            dispensary={dispensary}
            brandPlacement={brandPlacement}
          />

          <ActiveDealsCarousel
            dispensary={dispensary}
            deals={deals}
            dealCardLinks
          />

          <StaffPicksCarousel
            dispensaryId={dispensary.id}
            dispensaryRetailType={dispensary.retailType}
            dispensarySlug={dispensary.slug}
          />

          <ProductRecTrays
            dispensaryRetailType={dispensary.retailType}
            dispensarySlug={dispensary.slug}
            productsForCategoryCarousels={productsForCategoryCarousels.slice(
              0,
              1,
            )}
          />

          <PhenotypeCarousel
            dispensaryId={dispensary.id}
            dispensaryRetailType={dispensary.retailType}
            dispensarySlug={dispensary.slug}
          />

          <ProductRecTrays
            dispensaryRetailType={dispensary.retailType}
            dispensarySlug={dispensary.slug}
            productsForCategoryCarousels={productsForCategoryCarousels.slice(1)}
          />

          <RecentlyViewedMenuItems
            attributes={{ dispensarySlug: dispensary.slug }}
            displayStaffPick={true}
            isDispensaryPage={true}
            isDispensaryDualLicense={isDispensaryDualLicense(dispensary.tags)}
          />

          <ShopAllButton
            dispensaryRetailType={dispensary.retailType}
            dispensarySlug={dispensary.slug}
          />
        </div>
      </div>
    </div>
  );
};
export default PaidTierMenu;
